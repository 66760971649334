import { useMemo } from 'react';

export const useShowReplaceEmployee = ({
  currentEmployee = {},
  isInInput = false,
  isFilters = null
}) => {
  const replacementRecords = Array.isArray(currentEmployee.replacements)
    ? currentEmployee.replacements
    : [];

  const activeReplacement = replacementRecords.find(
    replacement => replacement.replacer
  );

  const replacementEmployee = activeReplacement
    ? activeReplacement.replacer
    : null;

  const isEmployeeReplaced =
    !!replacementEmployee && replacementRecords[0].isActive && !isInInput;

  const tooltipData = useMemo(
    () => ({
      isReplaced: isEmployeeReplaced && (isFilters === null || isFilters),
      replacer: replacementEmployee,
      fromDate: (replacementRecords[0] || {}).fromDate,
      toDate: (replacementRecords[0] || {}).toDate
    }),
    [isEmployeeReplaced, isFilters, replacementEmployee, replacementRecords]
  );

  return {
    replacerEmployee: replacementEmployee,
    isReplaced: isEmployeeReplaced,
    tooltipData
  };
};

export default useShowReplaceEmployee;
