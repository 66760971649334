import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_TASK_TEMPLATE,
  TYPE_ATTACHMENT,
  TYPE_PROJECT,
  TYPE_ORDER_STATUS,
  REPLACE_TAG_MODAL,
  EDIT_TAG_MODAL
} from 'constants/index';

import Button from 'components/common/button';
import ActionsDropdown from 'components/common/actions';
import Icon from 'components/common/icon';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { DeleteTagIcon } from 'components/common/icons/multicolor';
import { useModalContext } from 'components/app/modals-provider';

import { deleteTag, deleteTagFromWorkspace } from 'store/tags';

import styles from './action-menu.module.scss';

const ActionMenu = ({ color, setVisibleActionsDropdown, tag }) => {
  const { t } = useTranslation(['Common', 'TagsEditReplaceDelete']);
  const dispatch = useDispatch();

  const { showModal } = useModalContext();

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleDeleteModal = () => setVisibleDeleteModal(true);

  const handleVisibleActionsDropdown = visible => {
    setVisibleActionsDropdown(visible);
  };

  const handleReplaceModal = () => {
    showModal(REPLACE_TAG_MODAL, { tag });
    handleVisibleActionsDropdown(false);
  };

  const onConfirmDelete = async e => {
    e.stopPropagation();

    setIsDeleteLoading(true);
    try {
      await dispatch(deleteTagFromWorkspace({ tag }));
    } finally {
      setIsDeleteLoading(false);
      setVisibleDeleteModal(false);
    }
  };

  const onCloseDelete = e => {
    e.stopPropagation();
    setVisibleDeleteModal(false);
  };

  const onDeleteTag = () => {
    dispatch(deleteTag({ tag }));
  };

  const onEdit = () => {
    showModal(EDIT_TAG_MODAL, {
      searchValue: tag.name,
      editableTag: tag
    });
    handleVisibleActionsDropdown(false);
  };

  const getDeleteEntityTitle = entityType => {
    switch (entityType) {
      case TYPE_TASK:
        return 'DeleteFromTask';
      case TYPE_ASSET:
        return 'DeleteFromRecordDirectory';
      case TYPE_CONTACT:
        return 'DeleteFromContact';
      case TYPE_TASK_TEMPLATE:
        return 'DeleteFromTaskTemplate';
      case TYPE_ATTACHMENT:
        return 'DeleteFromFile';
      case TYPE_PROJECT:
        return 'DeleteFromProject';
      case TYPE_ORDER_STATUS:
        return 'DeleteFromOrder';
      default:
        return '';
    }
  };

  const actions = [
    {
      title: 'EditTag',
      ns: 'Common',
      allow: true,
      onClick: onEdit,
      icon: 'edit',
      className: styles.actionBtn,
      iconSize: 20
    },
    {
      title: 'Replace',
      ns: 'Common',
      allow: true,
      onClick: handleReplaceModal,
      icon: 'swap',
      className: styles.actionBtn,
      iconSize: 20
    },
    {
      title: getDeleteEntityTitle(tag.entityType),
      ns: 'Common',
      allow: tag.entityType || tag.entityId,
      onClick: onDeleteTag,
      icon: 'close',
      className: styles.actionBtn,
      iconSize: 20
    },
    {
      title: 'Delete',
      ns: 'Common',
      allow: true,
      onClick: handleDeleteModal,
      icon: 'delete',
      className: [styles.actionBtn, styles.deleteBtn],
      iconSize: 20
    }
  ];

  return (
    <div>
      <ActionsDropdown
        actions={actions}
        placement="topRight"
        className={styles.dropdownContent}
        afterChangeVisibilityCallback={handleVisibleActionsDropdown}
        afterSelectCallback={() => handleVisibleActionsDropdown(false)}
        shouldStopPropagation
      >
        <Button
          type="text"
          className={classnames(styles.dropdownBtn)}
          data-testid="action-menu-btn"
        >
          <Icon type="more" size={16} style={{ color }} />
        </Button>
      </ActionsDropdown>

      <ModalDeleteConfirm
        iconModal={DeleteTagIcon}
        visible={visibleDeleteModal}
        title={t('DeleteTagHeading', { ns: 'TagsEditReplaceDelete' })}
        description={t('DeleteTagDesc', { ns: 'TagsEditReplaceDelete' })}
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
        onClose={onCloseDelete}
      />
    </div>
  );
};

ActionMenu.propTypes = {
  setVisibleActionsDropdown: PropTypes.func,
  color: PropTypes.string,
  tag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    link: PropTypes.string
  }).isRequired
};

ActionMenu.defaultProps = {
  setVisibleActionsDropdown: () => {},
  color: ''
};

export default ActionMenu;
