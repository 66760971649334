import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { combineActions } from 'redux-actions';

import {
  CHANNEL_OPERATOR_REMOVED,
  DASHBOARD,
  NEW_USER_NOTIFICATION,
  NOT_DELIVERY_STATUS_MESSAGE,
  NOTIFICATIONS,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_DISCUSSION,
  TYPE_EMPLOYEE,
  TYPE_TASK,
  DISCUSSION_STATUS_ARCHIVED,
  COLLAPSED_WIDGET_PANEL,
  COLLAPSED_NOTIFICATIONS_FILTER_PANEL,
  COLLAPSED_NOTIFICATIONS_IN_BELL_FILTER_PANEL
} from 'constants/index';

import { archiveDiscussion } from 'store/discussions';

import handleActions from 'utils/redux-actions';

import { createTask } from '../tasks/actions';
import {
  getChatMessages,
  joinChatRooms,
  setEntityChatIsMessagesLoading,
  chatMessageNew,
  setIsNeedToReconnect,
  setSendingMessageData,
  setEntityChatHasNewMessages,
  chatMessageRead,
  setEntityChatRoomUuids,
  messageReactionSet,
  messageReactionUnset,
  setHighlightingMessageReaction,
  clearEntityChats,
  setOperatorIsReady,
  sendDraftMessage,
  deleteDraftMessage,
  notificationMessageRead,
  allNotificationMessagesRead,
  fetchUnreadedNotificationsCount,
  chatMessageNewOk,
  failedRemovedMessage,
  setIsNotSendedMessage,
  resendExistingMessage,
  employeeTyping,
  changeNotificationChatDisplay,
  fetchDiscussionStatuses,
  setChatEntity,
  setCollapsedWidgetPanel,
  setCollapsedNotificationsFilterPanel,
  setNotificationsFilterSender,
  clearFilter,
  setNotificationsFilterCreatedDateRange,
  setNotificationsFilterEntityType,
  setCollapsedNotificationsInBellFilterPanel,
  changeNotificationMessageTempIsRead
} from './actions';
import { getInitialValueStorage } from '../../hooks/common/use-local-storage';

const DRAFT_MESSAGES = 'draftMessages';

const getDraftMessages = () => {
  const storageDraftMessages = localStorage.getItem(DRAFT_MESSAGES);
  const draftMessages = JSON.parse(storageDraftMessages) || {};

  return draftMessages;
};

const getEntityByRoomUuid = (state, roomUuid) => {
  const entities = Object.keys(state.entities).reduce((result, entity) => {
    Object.keys(state.entities[entity].entityChats).find(id => {
      const { roomUuids = [] } = state.entities[entity].entityChats[id];

      if (roomUuids.includes(roomUuid)) {
        result.push({ entityId: id, entityType: entity });

        return true;
      }

      return false;
    });

    return result;
  }, []);

  return entities.length ? entities : [{ entityId: null, entityType: null }];
};

const findMessageIndex = ({ state, entityId, entityType, messageUuid }) => {
  let withSource = false;

  const index = (
    state.entities[entityType].entityChats[entityId].messages || []
  ).findIndex(m => {
    if (m.uuid === messageUuid || (m.source || {}).uuid === messageUuid) {
      withSource =
        m.uuid !== messageUuid && (m.source || {}).uuid === messageUuid;
      return true;
    }

    return false;
  });

  return { index, withSource };
};

function updateDraftMessages(state, message) {
  const draftMessages = getDraftMessages();

  delete draftMessages[message.uuid];

  localStorage.setItem(DRAFT_MESSAGES, JSON.stringify(draftMessages));

  if (
    state.sendingDraftMessages.length &&
    state.sendingDraftMessages.find(({ uuid }) => uuid === message.uuid)
  ) {
    state.sendingDraftMessages = state.sendingDraftMessages.filter(
      ({ uuid }) => uuid !== message.uuid
    );
  }
}

export const initialFilter = {
  sender: [],
  entityType: [],
  createdDateRange: {}
};

const initialState = {
  isNeedToReconnect: false,
  sendingMessageData: null, // when we need to send message without context provider wrapper
  isReady: false,
  sendingDraftMessages: [],
  notificationChatDisplay: {
    [DASHBOARD]: {},
    [NOTIFICATIONS]: {}
  },
  chatEntities: {},

  entities: {
    [TYPE_CONTACT]: {
      hasNewMessages: false,
      entityChats: {}
    },
    [TYPE_ASSET]: {
      entityChats: {}
    },
    [TYPE_TASK]: {
      entityChats: {}
    },
    [TYPE_EMPLOYEE]: {
      unreadedCount: {},

      entityChats: {},
      filter: {
        ...initialFilter
      },
      collapsedWidgetPanel: getInitialValueStorage(
        COLLAPSED_WIDGET_PANEL,
        true
      ),
      collapsedFilterPanel: getInitialValueStorage(
        COLLAPSED_NOTIFICATIONS_FILTER_PANEL,
        {
          opened: false,
          width: 380
        }
      ),
      collapsedInBellFilterPanel: getInitialValueStorage(
        COLLAPSED_NOTIFICATIONS_IN_BELL_FILTER_PANEL,
        {
          opened: false,
          width: 380
        }
      )
    },
    [TYPE_DISCUSSION]: {
      entityChats: {}
    }
  }
};

export default handleActions(
  {
    [setSendingMessageData]: (state, { payload }) => {
      state.sendingMessageData = payload;

      return state;
    },

    [setIsNeedToReconnect]: (state, { payload }) => {
      state.isNeedToReconnect = payload;

      return state;
    },

    [changeNotificationChatDisplay]: (state, { payload }) => {
      const { view, roomUuid, value } = payload;

      if (!roomUuid && !value) {
        state.notificationChatDisplay[view] = {};
      } else {
        state.notificationChatDisplay[view][roomUuid] = value;
      }

      return state;
    },

    [changeNotificationMessageTempIsRead]: (state, { payload }) => {
      const { entityId, messageUuid, value } = payload;

      const { index } = findMessageIndex({
        state,
        entityId,
        entityType: TYPE_EMPLOYEE,
        messageUuid
      });

      if (index !== -1) {
        state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages[
          index
        ].tempIsRead = value;
      }

      return state;
    },

    [setChatEntity]: (state, { payload }) => {
      const { roomUuid, entity } = payload;

      if (!roomUuid && !entity) {
        state.chatEntities = {};
      } else {
        state.chatEntities[roomUuid] = entity;
      }

      return state;
    },

    [setOperatorIsReady]: (state, { payload }) => {
      state.isReady = payload;

      return state;
    },

    [CHANNEL_OPERATOR_REMOVED]: state => {
      state.isNeedToReconnect = true;

      return state;
    },

    [setEntityChatIsMessagesLoading]: (state, { payload }) => {
      const { entityId, entityType, value } = payload;

      if (!state.entities[entityType].entityChats[entityId]) {
        state.entities[entityType].entityChats[entityId] = {};
      }

      state.entities[entityType].entityChats[
        entityId
      ].isNeedInitalFetchMessages = false;
      state.entities[entityType].entityChats[
        entityId
      ].isMessagesLoading = value;

      return state;
    },

    [setEntityChatHasNewMessages]: (state, { payload }) => {
      const { entityType, value } = payload;

      state.entities[entityType].hasNewMessages = value;

      return state;
    },

    [setEntityChatRoomUuids]: (state, { payload }) => {
      payload.forEach(data => {
        const { entityId, entityType, chats } = data;

        if (!state.entities[entityType].entityChats[entityId]) {
          state.entities[entityType].entityChats[entityId] = {};
        }

        state.entities[entityType].entityChats[entityId].roomUuids = [
          ...new Set([
            ...(state.entities[entityType].entityChats[entityId].roomUuids ||
              []),
            ...chats.map(c => c.roomUuid)
          ])
        ];
      });

      return state;
    },

    [clearEntityChats]: (state, { payload }) => {
      const { entityId, entityType } = payload;

      if (entityId) {
        state.entities[entityType].entityChats[entityId] = {};
      } else {
        state.entities[entityType].entityChats = {};
      }

      return state;
    },

    [joinChatRooms.SUCCEEDED]: (state, { payload }) => {
      payload.forEach(chat => {
        const { entityId, entityType, messages } = chat;

        state.entities[entityType].entityChats[
          entityId
        ].isMessagesLoading = false;

        if (
          !(state.entities[entityType].entityChats[entityId].messages || [])
            .length
        ) {
          state.entities[entityType].entityChats[entityId] = {
            ...state.entities[entityType].entityChats[entityId],
            ...chat,
            isNeedInitalFetchMessages: true,
            messages: messages.length
              ? [
                  maxBy(messages, message =>
                    moment(message.createdAt).valueOf()
                  )
                ]
              : []
          };
        }
      });

      return state;
    },

    [getChatMessages.SUCCEEDED]: (state, { payload, args }) => {
      const {
        entityId,
        entityType,
        messages: fetchedMessages,
        messageNewCount,
        count,
        unreadNotificationCount
      } = payload;

      const { notificationsRoomUuid, isRefetch } = args;

      const draftMessages = getDraftMessages();

      fetchedMessages.forEach(m => {
        if (draftMessages[m.uuid]) {
          delete draftMessages[m.uuid];
        }
      });

      localStorage.setItem(DRAFT_MESSAGES, JSON.stringify(draftMessages));

      if (state.entities[entityType].entityChats[entityId]) {
        const messages =
          state.entities[entityType].entityChats[entityId].messages || [];

        const notSendedMessages = Object.values(draftMessages).filter(
          m =>
            m.entityType === entityType &&
            m.entityId.toString() === entityId.toString()
        );

        const notDeliveryMessages = fetchedMessages
          .filter(({ status }) => status === NOT_DELIVERY_STATUS_MESSAGE)
          .map(m => ({
            ...m,
            isSending: false,
            isNotSended: true,
            isDraft: true
          }));

        if (isRefetch) {
          state.entities[entityType].entityChats[
            entityId
          ].messages = fetchedMessages;
        } else {
          state.entities[entityType].entityChats[entityId].messages = uniqBy(
            [
              ...fetchedMessages.filter(
                ({ status }) => status !== NOT_DELIVERY_STATUS_MESSAGE
              ),
              ...notDeliveryMessages,
              ...messages,
              ...notSendedMessages.map(m => ({
                ...m,
                isNotSended: true,
                isSending: false
              }))
            ],
            'uuid'
          );
        }

        state.entities[entityType].entityChats[
          entityId
        ].isMessagesLoading = false;

        state.entities[entityType].entityChats[
          entityId
        ].messageNewCount = messageNewCount;

        state.entities[entityType].entityChats[entityId].count = count;
      }

      if (notificationsRoomUuid) {
        state.entities[entityType].unreadedCount[
          notificationsRoomUuid
        ] = unreadNotificationCount;
      }

      return state;
    },

    [chatMessageNewOk]: (state, { payload }) => {
      const { message } = payload;

      updateDraftMessages(state, message);

      const entities = getEntityByRoomUuid(state, message.roomUuid);
      const [{ entityId, entityType }] = entities;

      if (entityType && state.entities[entityType].entityChats[entityId]) {
        const messages =
          state.entities[entityType].entityChats[entityId].messages || [];

        const draftMessageIndex = messages.findIndex(
          m => m.uuid === message.uuid && m.isDraft
        );

        if (draftMessageIndex !== -1) {
          messages[draftMessageIndex] = {
            ...message,
            isSending: false
          };
        } else {
          messages.push(message);
        }
      }

      return state;
    },

    [chatMessageNew]: (state, { payload }) => {
      const { message, isMyMessage, isNotification } = payload;

      updateDraftMessages(state, message);

      const entities = getEntityByRoomUuid(state, message.roomUuid);

      const isFilterApplied = filter =>
        filter.sender.length > 0 ||
        filter.entityType.length > 0 ||
        Object.keys(filter.createdDateRange).length > 0;

      const hasFilter = isFilterApplied(state.entities[TYPE_EMPLOYEE].filter);

      entities.forEach(({ entityId, entityType }) => {
        // this could be because roomUuids variable in TYPE_EMPLOYEE chat may contain uuids of other chats
        if (entityType === TYPE_EMPLOYEE && !isNotification) {
          return state;
        }

        if (entityType && state.entities[entityType].entityChats[entityId]) {
          const isNotDeliveryStatusMessage =
            message.status === NOT_DELIVERY_STATUS_MESSAGE;

          let messages =
            state.entities[entityType].entityChats[entityId].messages || [];

          const existingMessageIndex = messages.findIndex(
            m => m.uuid === message.uuid && !m.isDraft
          );

          const draftMessageIndex = messages.findIndex(
            m => m.uuid === message.uuid && m.isDraft
          );

          if (draftMessageIndex !== -1) {
            messages[draftMessageIndex] = {
              ...message,
              isSending: false
            };

            return state;
          }

          if (!hasFilter) {
            const messageRoomUuid = (message.source || {}).roomUuid;

            if (!isNotification && existingMessageIndex !== -1) {
              messages[existingMessageIndex] = {
                ...message,
                status: message.status,
                isSending: false,
                isDraft: isNotDeliveryStatusMessage,
                isNotSended: isNotDeliveryStatusMessage
              };
            } else if (
              isNotification &&
              message.source &&
              (state.notificationChatDisplay[DASHBOARD][messageRoomUuid] ||
                state.notificationChatDisplay[NOTIFICATIONS][messageRoomUuid])
            ) {
              const existingIndex = messages.findIndex(
                msg => msg.source && msg.source.roomUuid === messageRoomUuid
              );

              messages[existingIndex] = {
                ...message,
                isRead: true
              };
            } else {
              messages.push(message);
            }

            if (
              isNotification &&
              !state.notificationChatDisplay[DASHBOARD][messageRoomUuid] &&
              !state.notificationChatDisplay[NOTIFICATIONS][messageRoomUuid]
            ) {
              const groupedMessages = messages.filter(
                msg =>
                  msg.source &&
                  msg.source.roomUuid &&
                  msg.source.roomUuid === messageRoomUuid &&
                  msg.uuid !== message.uuid
              );

              const groupedMessagesUuids = groupedMessages.map(msg => msg.uuid);

              messages = messages.filter(
                msg => !groupedMessagesUuids.includes(msg.uuid)
              );
            }

            state.entities[entityType].entityChats[entityId].messages = [
              ...messages
            ];

            state.entities[entityType].entityChats[entityId].count += 1;

            if (!isMyMessage) {
              state.entities[entityType].entityChats[
                entityId
              ].messageNewCount += 1;
            }

            if (isNotification) {
              if (
                !state.entities[TYPE_EMPLOYEE].unreadedCount[message.roomUuid]
              ) {
                state.entities[TYPE_EMPLOYEE].unreadedCount[
                  message.roomUuid
                ] = 0;
              }
            }
          }

          if (!(isMyMessage && message.entityType === TYPE_DISCUSSION)) {
            state.entities[TYPE_EMPLOYEE].unreadedCount[message.roomUuid] += 1;
          }

          if (
            (state.entities[entityType].entityChats[entityId].typingList || [])
              .length
          ) {
            state.entities[entityType].entityChats[
              entityId
            ].typingList = state.entities[entityType].entityChats[
              entityId
            ].typingList.filter(t => t.messageUuid !== message.uuid);
          }
        }
      });

      return state;
    },

    [setIsNotSendedMessage]: (state, { payload }) => {
      const { roomUuid, uuid } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      const [{ entityId, entityType }] = entities;

      if (entityType && state.entities[entityType].entityChats[entityId]) {
        const messages =
          state.entities[entityType].entityChats[entityId].messages || [];

        const messageIsNotSendedIndex = messages.findIndex(
          m => m.uuid === uuid
        );

        if (messageIsNotSendedIndex !== -1) {
          messages[messageIsNotSendedIndex] = {
            ...messages[messageIsNotSendedIndex],
            isNotSended: true,
            isSending: false
          };

          state.entities[entityType].entityChats[entityId].messages = [
            ...messages
          ];
        }
      }

      return state;
    },

    [chatMessageRead]: (state, { payload }) => {
      const {
        roomUuid,
        messageUuid,
        messageNewCount,
        unreadNotificationsCount
      } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      entities.forEach(({ entityId, entityType }) => {
        if (entityType && state.entities[entityType].entityChats[entityId]) {
          const { index } = findMessageIndex({
            state,
            entityId,
            entityType,
            messageUuid
          });

          if (
            entityType === TYPE_EMPLOYEE &&
            state.entities[TYPE_EMPLOYEE].entityChats[entityId] &&
            index !== -1
          ) {
            state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages[
              index
            ].isRead = true;

            state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages[
              index
            ].sourceChatUnreadMessagesCount = messageNewCount;

            if (typeof unreadNotificationsCount === 'number') {
              state.entities[TYPE_EMPLOYEE].unreadedCount[
                state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages[
                  index
                ].roomUuid
              ] = unreadNotificationsCount;
            }
          }

          // должно быть payload.messageNewCount, но пока 0, потому что работает не совсем корректно
          if (entityType && state.entities[entityType].entityChats[entityId]) {
            state.entities[entityType].entityChats[
              entityId
            ].messageNewCount = 0;
          }
        }
      });

      return state;
    },

    [messageReactionSet]: (state, { payload }) => {
      const { roomUuid, messageUuid, code } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      entities.forEach(({ entityId, entityType }) => {
        if (entityType && state.entities[entityType].entityChats[entityId]) {
          const { index, withSource } = findMessageIndex({
            state,
            entityId,
            entityType,
            messageUuid
          });

          if (index !== -1) {
            const reactions = withSource
              ? state.entities[entityType].entityChats[entityId].messages[index]
                  .source.reactions
              : state.entities[entityType].entityChats[entityId].messages[index]
                  .reactions;

            reactions.push({
              ...payload,
              isFirst: !reactions.find(r => r.code === code)
            });
          }
        }
      });

      return state;
    },

    [messageReactionUnset]: (state, { payload }) => {
      const { roomUuid, messageUuid, code, sender } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      entities.forEach(({ entityId, entityType }) => {
        if (entityType && state.entities[entityType].entityChats[entityId]) {
          const { index, withSource } = findMessageIndex({
            state,
            entityId,
            entityType,
            messageUuid
          });

          if (index !== -1) {
            const reactions = withSource
              ? state.entities[entityType].entityChats[entityId].messages[index]
                  .source.reactions
              : state.entities[entityType].entityChats[entityId].messages[index]
                  .reactions;

            const newReactions = reactions
              .filter(
                r =>
                  !(
                    r.code === code &&
                    r.sender.employee.id === sender.employee.id
                  )
              )
              .map(r => ({ ...r, isFirst: false }));

            if (withSource) {
              state.entities[entityType].entityChats[entityId].messages[
                index
              ].source.reactions = newReactions;
            } else {
              state.entities[entityType].entityChats[entityId].messages[
                index
              ].reactions = newReactions;
            }
          }
        }
      });

      return state;
    },

    [setHighlightingMessageReaction]: (state, { payload }) => {
      const { roomUuid, messageUuid, code, value } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      entities.forEach(({ entityId, entityType }) => {
        if (entityType && state.entities[entityType].entityChats[entityId]) {
          const { index, withSource } = findMessageIndex({
            state,
            entityId,
            entityType,
            messageUuid
          });

          if (index !== -1) {
            const reactions = withSource
              ? state.entities[entityType].entityChats[entityId].messages[index]
                  .source.reactions
              : state.entities[entityType].entityChats[entityId].messages[index]
                  .reactions;

            const newReactions = reactions.map(r => ({
              ...r,
              isFirst: false,
              needHighlighting: r.code === code && value
            }));

            if (withSource) {
              state.entities[entityType].entityChats[entityId].messages[
                index
              ].source.reactions = newReactions;
            } else {
              state.entities[entityType].entityChats[entityId].messages[
                index
              ].reactions = newReactions;
            }
          }
        }
      });

      return state;
    },

    [sendDraftMessage]: (state, { payload }) => {
      const { entityId, entityType, isResend, ...message } = payload;

      if (
        entityType &&
        (state.entities[entityType].entityChats[entityId] || {}).messages &&
        !isResend
      ) {
        state.entities[entityType].entityChats[entityId].messages.push(message);
      }

      const draftMessages = getDraftMessages();

      draftMessages[message.uuid] = {
        entityId,
        entityType,
        ...message
      };

      localStorage.setItem(DRAFT_MESSAGES, JSON.stringify(draftMessages));

      state.sendingDraftMessages = [...state.sendingDraftMessages, message];

      return state;
    },

    [deleteDraftMessage]: (state, { payload }) => {
      const { uuid, entityId, entityType } = payload;
      const draftMessages = getDraftMessages();

      state.entities[entityType].entityChats[
        entityId
      ].messages = state.entities[entityType].entityChats[
        entityId
      ].messages.filter(m => m.uuid !== uuid);

      if (draftMessages[uuid]) {
        delete draftMessages[uuid];

        localStorage.setItem(DRAFT_MESSAGES, JSON.stringify(draftMessages));
      }

      return state;
    },

    [notificationMessageRead]: (state, { payload }) => {
      const {
        roomUuid,
        messageUuid,
        isRead,
        unreadNotificationCount,
        unreadMessagesCount,
        parentNotificationUuid,
        firstUnreadChatMessageId,
        firstUnreadNotificationId
      } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);
      const [{ entityId, entityType }] = entities;

      if (entityType && state.entities[entityType].entityChats[entityId]) {
        if (typeof unreadNotificationCount === 'number') {
          state.entities[entityType].unreadedCount[
            roomUuid
          ] = unreadNotificationCount;
        }

        const { index } = findMessageIndex({
          state,
          entityId,
          entityType,
          messageUuid: parentNotificationUuid || messageUuid
        });

        if (index !== -1) {
          state.entities[entityType].entityChats[entityId].messages[
            index
          ].sourceChatUnreadMessagesCount = unreadMessagesCount;

          state.entities[entityType].entityChats[entityId].messages[
            index
          ].isRead = isRead;

          state.entities[entityType].entityChats[entityId].messages[
            index
          ].firstUnreadMessageId = firstUnreadChatMessageId;

          state.entities[entityType].entityChats[entityId].messages[
            index
          ].firstUnreadNotificationId = firstUnreadNotificationId;
        }
      }

      return state;
    },

    [allNotificationMessagesRead]: (state, { payload }) => {
      const { roomUuid } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);
      const [{ entityId, entityType }] = entities;

      if (entityType && state.entities[entityType].entityChats[entityId]) {
        state.entities[entityType].entityChats[
          entityId
        ].messages = state.entities[entityType].entityChats[
          entityId
        ].messages.map(m => ({ ...m, isRead: true }));

        state.entities[entityType].unreadedCount[roomUuid] = 0;
      }

      return state;
    },

    [fetchUnreadedNotificationsCount.SUCCEEDED]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].unreadedCount = payload.reduce(
        (acc, curr) => {
          const roomUuid = curr.roomUuid || curr.room_uuid;
          acc[roomUuid] = curr.count;

          return acc;
        },
        {}
      );

      return state;
    },

    [NEW_USER_NOTIFICATION]: (state, { payload }) => {
      if (payload.templateUid === 438) {
        state.entities[TYPE_CONTACT].hasNewMessages = true;
      }

      return state;
    },

    [failedRemovedMessage]: (state, { payload }) => {
      const { roomUuid, uuid } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);
      const [{ entityId, entityType }] = entities;

      if (entityType && state.entities[entityType].entityChats[entityId]) {
        const { index } = findMessageIndex({
          state,
          entityId,
          entityType,
          messageUuid: uuid
        });

        state.entities[entityType].entityChats[
          entityId
        ].messages = state.entities[entityType].entityChats[
          entityId
        ].messages.filter((_, i) => i !== index);
      }

      return state;
    },

    [resendExistingMessage]: (state, { payload }) => {
      const { roomUuid, uuid } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      const [{ entityId, entityType }] = entities;

      const { index } = findMessageIndex({
        state,
        entityId,
        entityType,
        messageUuid: uuid
      });

      state.entities[entityType].entityChats[entityId].messages[index] = {
        ...state.entities[entityType].entityChats[entityId].messages[index],
        isSending: true,
        isDraft: true,
        isNotSended: false
      };

      return state;
    },

    [employeeTyping]: (state, { payload }) => {
      const { roomUuid } = payload;

      const entities = getEntityByRoomUuid(state, roomUuid);

      entities.forEach(({ entityId, entityType }) => {
        if (entityType && state.entities[entityType].entityChats[entityId]) {
          if (!state.entities[entityType].entityChats[entityId].typing) {
            state.entities[entityType].entityChats[entityId].typingList = [];
          }

          state.entities[entityType].entityChats[entityId].typingList.push(
            payload
          );
        }
      });

      return state;
    },

    [fetchDiscussionStatuses.SUCCEEDED]: (state, action) => {
      const { entityId } = action.args;
      const discussions = action.payload;

      if (state.entities[TYPE_EMPLOYEE].entityChats[entityId]) {
        discussions.forEach(discussion => {
          state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages.forEach(
            message => {
              if (
                message.source &&
                message.source.roomUuid === discussion.chatId
              ) {
                message.status = discussion.status;
              }
            }
          );
        });
      }

      return state;
    },

    [archiveDiscussion.SUCCEEDED]: (state, { payload }) => {
      const roomUuid = payload.chats[0].uuid;

      const entities = getEntityByRoomUuid(state, roomUuid);
      const [{ entityId }] = entities;

      if (state.entities[TYPE_EMPLOYEE].entityChats[entityId]) {
        state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages.forEach(
          message => {
            if (message.source && message.source.roomUuid === roomUuid) {
              message.status = DISCUSSION_STATUS_ARCHIVED;
            }
          }
        );
      }

      return state;
    },

    [createTask.SUCCEEDED]: (state, action) => {
      const isDiscussion = (action.args.task || {}).discussion;

      if (isDiscussion) {
        const roomUuid = action.payload.chats[0].uuid;

        const entities = getEntityByRoomUuid(state, roomUuid);

        const [{ entityId }] = entities;

        if (state.entities[TYPE_EMPLOYEE].entityChats[entityId]) {
          const messages =
            state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages || [];

          const messageIndex = messages.findIndex(
            m => m.source && m.source.roomUuid === roomUuid
          );

          if (messageIndex !== -1) {
            messages[messageIndex] = {
              ...messages[messageIndex],
              status: action.payload.status,
              notificationData: {
                ...messages[messageIndex].notificationData,
                entityType: TYPE_TASK,
                entityTitle: action.payload.title,
                taskId: action.payload.id
              }
            };

            state.entities[TYPE_EMPLOYEE].entityChats[entityId].messages = [
              ...messages
            ];

            state.notificationChatDisplay[DASHBOARD][roomUuid] = false;
            state.notificationChatDisplay[NOTIFICATIONS][roomUuid] = false;
          }
        }
      }

      return state;
    },

    [setCollapsedWidgetPanel]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].collapsedWidgetPanel = payload;

      return state;
    },

    [setCollapsedNotificationsFilterPanel]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].collapsedFilterPanel = {
        ...state.entities[TYPE_EMPLOYEE].collapsedFilterPanel,
        opened: payload.opened
      };

      return state;
    },

    [setCollapsedNotificationsInBellFilterPanel]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].collapsedInBellFilterPanel = {
        ...state.entities[TYPE_EMPLOYEE].collapsedInBellFilterPanel,
        opened: payload.opened
      };

      return state;
    },

    [combineActions(
      setNotificationsFilterSender,
      setNotificationsFilterCreatedDateRange,
      setNotificationsFilterEntityType,
      clearFilter
    )]: (state, { payload }) => {
      if (state.entities[TYPE_EMPLOYEE].entityChats[payload.entityId]) {
        state.entities[TYPE_EMPLOYEE].entityChats[
          payload.entityId
        ].messages = [];
      }

      return state;
    },

    [setNotificationsFilterSender]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].filter.sender = payload.value;

      return state;
    },

    [setNotificationsFilterCreatedDateRange]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].filter.createdDateRange = payload.value;

      return state;
    },

    [setNotificationsFilterEntityType]: (state, { payload }) => {
      state.entities[TYPE_EMPLOYEE].filter.entityType = payload.value;

      return state;
    },

    [clearFilter]: state => {
      state.entities[TYPE_EMPLOYEE].filter = initialFilter;

      return state;
    }
  },
  initialState
);
