import React from 'react';
import PropTypes from 'prop-types';

import Chat from 'components/common/chat';
import useContactChat from 'components/common/chat/entity-hooks/use-contact-chat';

const ContactChat = ({ contact, destination, isEntityLoading, canView }) => {
  const {
    isLoading,
    messagesRef,
    renderedMessages,
    toBottom,
    renderedControls,
    hasMessages,
    isJoined,
    hasMore,
    messagesCount,
    loadMore
  } = useContactChat({
    contact,
    destination
  });

  return (
    <Chat
      messagesRef={messagesRef}
      isLoading={isLoading}
      hasMessages={hasMessages}
      messages={renderedMessages}
      toBottom={toBottom}
      controls={renderedControls}
      isEntityLoading={isEntityLoading}
      canView={canView}
      hasMore={hasMore}
      isJoined={isJoined}
      messagesCount={messagesCount}
      threshold={500}
      loadMore={loadMore}
    />
  );
};

ContactChat.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    chats: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  destination: PropTypes.shape({
    entityType: PropTypes.string,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
};

ContactChat.defaultProps = {
  contact: {}
};

export default ContactChat;
