import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classnames from 'classnames';

import { useCollapsedSider } from 'components/common/layout/template/sider/use-collapsed-sider';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import theme from 'theme';

import styles from './right-side-panel.module.scss';

const WIDTH = 354;

export const RightSidePanel = forwardRef(
  (
    {
      children,
      width,
      collapsed,
      className,
      btnClassName,
      setCollapsed,
      openTipText,
      hideTipText
    },
    ref
  ) => {
    const [collapsedSider, setCollapsedSider] = useCollapsedSider();

    const handleCollapsed = value => {
      if (!collapsedSider) {
        setCollapsedSider(true);
      }

      setCollapsed(value);
    };

    useEffect(() => {
      if (!collapsed) {
        setCollapsedSider(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={classnames(styles.root, className)}>
        <Tooltip
          placement="right"
          mouseEnterDelay={0.5}
          title={collapsed ? openTipText : hideTipText}
        >
          <Button
            type="default"
            style={{
              backgroundColor: collapsed
                ? theme.color['brand-95']
                : theme.color['black-98'],
              borderLeft: collapsed
                ? `0.01em solid ${theme.color.brand}`
                : 'none'
            }}
            className={classnames(styles.btnCollapsed, btnClassName)}
            onClick={() => handleCollapsed(!collapsed)}
          >
            <div
              style={{
                backgroundColor: collapsed
                  ? theme.color['brand-95']
                  : theme.color['black-98'],
                border: collapsed
                  ? `1px solid ${theme.color.brand}`
                  : `1px solid ${theme.color['black-85']}`
              }}
              className={styles.ellipsis}
            >
              <Icon
                type="right"
                size={12}
                color={collapsed ? 'brand' : 'black-55'}
                style={{
                  transform: collapsed ? 'rotate(180deg)' : null,
                  transition: '0.2s ease-in-out transform'
                }}
              />
            </div>
          </Button>
        </Tooltip>

        <div
          style={{
            padding: collapsed ? '24px 0' : '24px 32px 24px 16px',
            width: collapsed ? '1px' : width,
            opacity: collapsed ? 0 : 1,
            transition: '0.2s ease-in-out all',
            overflowY: 'auto'
          }}
          ref={ref}
        >
          {children}
        </div>
      </div>
    );
  }
);

RightSidePanel.propTypes = {
  width: PropTypes.number,
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  className: PropTypes.string,
  btnClassName: PropTypes.string
};

RightSidePanel.defaultProps = {
  width: WIDTH,
  className: undefined,
  btnClassName: undefined
};

export default RightSidePanel;
